<template>
  <v-main class="mobile-search-container">
    <div>
      <v-row align="start" justify="center" class="mobile-search-bar">
        <v-col cols="4">
          <v-select
            :items="categories"
            label="选择类型"
            v-model="selectedTypeModel"
            solo
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            placeholder="请输入内容"
            solo
            v-model="question"
            @keydown.enter="search"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn color="#FCA311" @click="search" class="px-10 white--text" large>
          <v-icon>mdi-search-web</v-icon>搜索内容
        </v-btn>
      </v-row>
      <!-- <v-row justify="center" class="mt-4">
        <v-btn color="#FCA311" class="white--text" :disabled="!clickedNode">
          显示详情
          <v-icon right dark>
            mdi-eye-outline
          </v-icon>
        </v-btn>
        <v-btn color="#14213D" class="white--text ml-4" :disabled="!isSpread">
          扩展节点
          <v-icon right dark>
            mdi-call-split
          </v-icon>
        </v-btn>
      </v-row> -->
      <v-row justify="center" class="mt-4">
        <mobile-graph
          :parentgraphData="graphData"
          :selectedType="selectedType"
        />
      </v-row>
    </div>
    <template>
      <v-snackbar v-model="snackbarList.searchSucc" top :timeout="3000">
        {{ snackbarList.searchSuccMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbarList.searchSucc = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="snackbarList.questionEmpty" top :timeout="3000">
        搜索内容不能为空
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbarList.questionEmpty = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="snackbarList.typeEmpty" top :timeout="3000">
        请先选择要搜索的类型
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbarList.typeEmpty = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
    <v-overlay :absolute="true" :value="isLoading">
      <div>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-overlay>
  </v-main>
</template>
<script>
import axios from '@/service'
import MobileGraph from './MobileGraph.vue'
import { getToken } from '@/utils'
export default {
  components: { MobileGraph },
  data() {
    return {
      question: null,
      selectedType: '',
      res: null,
      categoriesList: {
        学生: 'Student',
        老师: 'Teacher',
        竞赛: 'Competition',
        团队: 'Group',
      },
      snackbarList: {
        searchSucc: false,
        questionEmpty: false,
        typeEmpty: false,
        searchSuccMsg: '',
      },
      graphData: {},
      selectedTypeModel: '',
      isLoading: false,
    }
  },
  computed: {
    categories() {
      return Object.keys(this.categoriesList)
    },
    spreadList: {
      get() {
        return this.$store.state.graphDetails.spreadList
      },
      set(value) {
        this.$store.commit('setspreadList', value)
      },
    },
    clickedNode: {
      get() {
        return this.$store.state.graphDetails.clickedNode
      },
      set(value) {
        this.$store.commit('setclickNode', value)
      },
    },
    isSpread() {
      return !this.spreadList[this.clickedNode]
    },
  },
  methods: {
    async search() {
      if (this.question) {
        if (!this.selectedTypeModel) {
          this.snackbarList.typeEmpty = true
          return
        }
        window.scrollTo(
          0,
          document.body.scrollHeight || document.documentElement.scrollHeight,
        )
        this.isLoading = true
        const {
          data: { data: getData },
        } = await axios.get(
          `/find/default/multi/${
            this.categoriesList[this.selectedTypeModel]
          }?name=${this.question}`,
          {
            headers: {
              Authorization: `${getToken()}`,
            },
          },
        )
        this.isLoading = false
        this.graphData = getData
        this.snackbarList.searchSucc = true
        console.log(getData)
        if (!Object.keys(getData).length) {
          this.snackbarList.searchSuccMsg = `未能检索出数据`
          return
        }
        this.snackbarList.searchSuccMsg = `搜索成功，检索出 ${this.graphData.relationCount} 条关系`
        this.res = {
          ...getData,
          graphType: this.categoriesList[this.selectedTypeModel],
        }
        this.$emit('refrenshData', this.res)
      } else {
        this.snackbarList.questionEmpty = true
      }
    },
  },
}
</script>

<style lang="scss">
.mobile-search {
  &-container {
    display: flex;
    justify-content: center;
    flex-shrink: 1;
  }
  &-bar {
    margin-top: 1.2rem;
  }
}
.v-text-field__details {
  display: none !important;
}
</style>
