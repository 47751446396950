<template>
  <div>
    <v-row justify="center" class="mb-4">
      <v-dialog v-model="isDialog" max-width="800">
        <template v-slot:activator="{ on, attrs }"
          ><v-btn
            color="#FCA311"
            class="white--text"
            v-bind="attrs"
            v-on="on"
            :disabled="!clickedNode"
          >
            显示详情
            <v-icon right dark>
              mdi-eye-outline
            </v-icon>
          </v-btn></template
        >

        <v-card>
          <v-card-title class="headline lighten-2">
            <v-icon medium class="mr-2">mdi-eye-outline</v-icon>
            详细信息
          </v-card-title>
          <v-list class="ml-4">
            <v-list-item two-line v-for="(value, key) of centerList" :key="key">
              <v-list-item-content>
                <v-list-item-title class="text-body-1">{{
                  key
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-body-2">{{
                  value
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="isDialog = false">
              关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        color="#14213D"
        class="white--text ml-4"
        :disabled="!!spreadList[clickedNode]"
        @click="emitSpread"
      >
        扩展节点
        <v-icon right dark>
          mdi-call-split
        </v-icon>
      </v-btn>
    </v-row>
    <v-col cols="12">
      <div id="graph"></div>
    </v-col>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import G6 from '@antv/g6'
import axios from '@/service'
import { getToken } from '@/utils'
export default {
  name: 'Graph',
  data() {
    return {
      graph: null,
      graphData: {
        nodes: [],
        edges: [],
      },
      nodeDsc: {},
      multiEdgeMap: {},
      nodesMap: {},
      isDialog: false,
      isLoading: false,
      spreadData: null,
      idMap: null,
      colorList: [
        '#FCA311',
        '#3366FF',
        '#55B519',
        '#DBAF24',
        '#FF632B',
        '#7eca9c',
        '#d8ac9c',
      ],
    }
  },
  props: {
    parentgraphData: {
      type: Object,
    },
    selectedType: String,
  },
  computed: {
    summary: {
      get() {
        return this.$store.state.graphDetails.summary
      },
      set(value) {
        this.$store.commit('setSummary', value)
      },
    },
    isSummaryShow: {
      get() {
        return this.$store.state.graphDetails.isSummaryShow
      },
      set(value) {
        this.$store.commit('setisSummaryShow', value)
      },
    },
    isDetailsShow: {
      get() {
        return this.$store.state.graphDetails.isDetailsShow
      },
      set(value) {
        this.$store.commit('setisDetailsShow', value)
      },
    },
    clickedNode: {
      get() {
        return this.$store.state.graphDetails.clickedNode
      },
      set(value) {
        this.$store.commit('setclickNode', value)
      },
    },
    centerTitle: {
      get() {
        return this.$store.state.graphDetails.centerTitle
      },
      set(value) {
        this.$store.commit('setcenterTitle', value)
      },
    },
    centerList: {
      get() {
        return this.$store.state.graphDetails.centerList
      },
      set(value) {
        this.$store.commit('setcenterList', value)
      },
    },
    spreadList: {
      get() {
        return this.$store.state.graphDetails.spreadList
      },
      set(value) {
        this.$store.commit('setspreadList', value)
      },
    },
  },
  methods: {
    graphInit() {
      const toolbar = new G6.ToolBar({
        container: document.getElementById('toolbar'),
        getContent: () => {
          const outDiv = document.createElement('div')
          outDiv.style.padding = '4px 0'
          outDiv.style.width = '110px'
          outDiv.innerHTML = `<ul style="margin: 0; padding: 0">
              <li code="short"><span class="iconfont icon-find"></span></li>
              <li code="add"><span class="iconfont icon-Add"></span></li>
              <li code="remove"><span class="iconfont icon-Remove"></span></li>
            </ul>`
          return outDiv
        },
        handleClick: (code, graph) => {
          if (code === 'add') {
            graph.zoom(1.1, { x: 0, y: 0 })
          }
          if (code === 'remove') {
            graph.zoom(0.9, { x: 0, y: 0 })
          }
        },
      })
      const tooltip = new G6.Tooltip({
        getContent(e) {
          const outDiv = document.createElement('div')
          outDiv.innerHTML = `<p style="margin: 0; padding: 0">名称：${e.item.getModel()
            .label || e.item.getModel().id}</p>`
          return outDiv
        },
      })

      G6.registerBehavior('finger-drag-node', {
        dragging: false,
        getEvents() {
          return {
            touchstart: 'onDragStart',
            touchmove: 'onDrag',
            touchend: 'onDragEnd',
          }
        },
        onDragStart(e) {
          const self = this
          self.dragging = false

          const clientX = +e.clientX
          const clientY = +e.clientY

          this.origin = {
            x: clientX,
            y: clientY,
          }
        },
        onDrag(e) {
          if (!this.dragging) {
            this.dragging = true
          }
          this.updateViewport(e)
        },
        onDragEnd(e) {
          if (this.enableOptimize) {
            const edges = this.graph.getEdges()
            for (let i = 0, len = edges.length; i < len; i++) {
              const shapes = edges[i].get('group').get('children')
              if (!shapes) continue
              shapes.forEach(shape => {
                shape.show()
              })
            }
            const nodes = this.graph.getNodes()
            for (let j = 0, nodeLen = nodes.length; j < nodeLen; j++) {
              const container = nodes[j].getContainer()
              const children = container.get('children')
              for (const child of children) {
                const isKeyShape = child.get('isKeyShape')
                if (!isKeyShape) {
                  child.show()
                }
              }
            }
          }
          this.updateViewport(e)
          this.dragging = false
        },
        updateViewport(e) {
          const { origin } = this
          const clientX = +e.clientX
          const clientY = +e.clientY

          if (isNaN(clientX) || isNaN(clientY)) {
            return
          }
          let dx = clientX - origin.x
          let dy = clientY - origin.y
          if (this.get('direction') === 'x') {
            dy = 0
          } else if (this.get('direction') === 'y') {
            dx = 0
          }
          this.origin = {
            x: clientX,
            y: clientY,
          }
          const width = this.graph.get('width')
          const height = this.graph.get('height')
          const graphCanvasBBox = this.graph.get('canvas').getCanvasBBox()
          if (
            (graphCanvasBBox.minX <= width &&
              graphCanvasBBox.minX + dx > width) ||
            (graphCanvasBBox.maxX >= 0 && graphCanvasBBox.maxX + dx < 0)
          ) {
            dx = 0
          }
          if (
            (graphCanvasBBox.minY <= height &&
              graphCanvasBBox.minY + dy > height) ||
            (graphCanvasBBox.maxY >= 0 && graphCanvasBBox.maxY + dy < 0)
          ) {
            dy = 0
          }
          if (dx === 0 && dy === 0) return
          this.graph.translate(dx, dy)
        },
      })

      G6.registerBehavior('activate-node', {
        getDefaultCfg() {
          return {
            multiple: true,
          }
        },
        getEvents() {
          return {
            'node:touchstart': 'setAllItemStates',
            'canvas:touchstart': 'clearActiveState',
          }
        },
        setAllItemStates(e) {
          const item = e.item
          const graph = this.graph
          this.item = item
          if (!this.shouldUpdate(e.item, { event: e, action: 'activate' })) {
            return
          }
          const self = this
          const activeState = this.activeState
          const inactiveState = this.inactiveState
          const nodes = graph.getNodes()
          const edges = graph.getEdges()
          const nodeLength = nodes.length
          const edgeLength = edges.length
          for (let i = 0; i < nodeLength; i++) {
            const node = nodes[i]
            const hasSelected = node.hasState('selected')
            if (self.resetSelected) {
              if (hasSelected) {
                graph.setItemState(node, 'selected', false)
              }
            }
            graph.setItemState(node, activeState, false)
            if (inactiveState) {
              graph.setItemState(node, inactiveState, true)
            }
          }

          for (let i = 0; i < edgeLength; i++) {
            const edge = edges[i]
            graph.setItemState(edge, activeState, false)
            if (inactiveState) {
              graph.setItemState(edge, inactiveState, true)
            }
          }

          if (inactiveState) {
            graph.setItemState(item, inactiveState, false)
          }
          graph.setItemState(item, activeState, true)

          const rEdges = item.getEdges()
          const rEdgeLegnth = rEdges.length
          for (let i = 0; i < rEdgeLegnth; i++) {
            const edge = rEdges[i]
            let otherEnd
            if (edge.getSource() === item) {
              otherEnd = edge.getTarget()
            } else {
              otherEnd = edge.getSource()
            }
            if (inactiveState) {
              graph.setItemState(otherEnd, inactiveState, false)
            }
            graph.setItemState(otherEnd, activeState, true)
            graph.setItemState(edge, inactiveState, false)
            graph.setItemState(edge, activeState, true)
            edge.toFront()
          }
          graph.emit('afteractivaterelations', {
            item: e.item,
            action: 'activate',
          })
        },
        onCanvasTouch(e) {
          if (this.shouldUpdate(e)) {
            this.removeNodesState()
          }
        },
        clearActiveState(e) {
          const self = this
          const graph = self.get('graph')
          if (!self.shouldUpdate(e.item, { event: e, action: 'deactivate' })) {
            return
          }

          const activeState = this.activeState
          const inactiveState = this.inactiveState

          const autoPaint = graph.get('autoPaint')
          graph.setAutoPaint(false)
          const nodes = graph.getNodes() || []
          const combos = graph.getCombos() || []
          const edges = graph.getEdges() || []
          const vEdges = graph.get('vedges') || []
          const nodeLength = nodes.length
          const comboLength = combos.length
          const edgeLength = edges.length
          const vEdgeLength = vEdges.length

          for (let i = 0; i < nodeLength; i++) {
            const node = nodes[i]
            graph.clearItemStates(node, [activeState, inactiveState])
          }
          for (let i = 0; i < comboLength; i++) {
            const combo = combos[i]
            graph.clearItemStates(combo, [activeState, inactiveState])
          }
          for (let i = 0; i < edgeLength; i++) {
            const edge = edges[i]
            graph.clearItemStates(edge, [
              activeState,
              inactiveState,
              'deactivate',
            ])
          }
          for (let i = 0; i < vEdgeLength; i++) {
            const vEdge = vEdges[i]
            graph.clearItemStates(vEdge, [
              activeState,
              inactiveState,
              'deactivate',
            ])
          }
          graph.paint()
          graph.setAutoPaint(autoPaint)
          graph.emit('afteractivaterelations', {
            item: e.item || self.get('item'),
            action: 'deactivate',
          })
        },
      })
      this.graph = new G6.Graph({
        container: 'graph',
        width: 1200,
        height: 700,
        fitView: true,
        fitViewPadding: [20, 40, 50, 20],
        defaultNode: {
          size: 85,
          style: {
            fill: '#FCA311',
            stroke: '#FCA311',
            lineWidth: 1,
            opacity: 0.9,
            cursor: 'pointer',
          },
          labelCfg: {
            style: {
              fill: '#FFFFFF',
              fontSize: 18,
            },
          },
        },
        defaultEdge: {
          color: '#000',
          autoRotate: true,
          type: 'line',
          labelCfg: {
            style: {
              fill: '#000',
              fontSize: 17,
            },
            autoRotate: true,
            refY: 15,
          },
        },
        nodeStateStyles: {
          active: {
            fill: '#fc7b11',
          },
          inactive: {
            fill: '#FCA311',
          },
        },
        edgeStateStyles: {
          active: {
            stroke: '#f58634',
            'text-shape': {
              fill: '#14213D',
            },
          },
          inactive: {
            'text-shape': {
              fill: '#fff',
            },
          },
        },
        modes: {
          default: [
            // 'drag-canvas',
            'zoom-canvas',
            'finger-drag-node',
            'drag-node',
            {
              type: 'activate-node',
              multiple: false,
              activeState: 'active',
              inactiveState: 'inactive',
            },
          ],
        },
        layout: {
          type: 'force',
        },
        plugins: [toolbar, tooltip],
        // plugins: [filterLens]
      })
      this.idMap = new Set()
    },
    refreshDragedNodePosition(e) {
      const model = e.item.get('model')
      model.fx = e.x
      model.fy = e.y
    },
    graphRefresh() {
      this.multiEdgeMap = {}
      this.nodesMap = {}
      this.clickedNode = null
      this.spreadList = {}
    },
    graphEventInit() {
      this.graph.on('node:click', e => {
        this.isDetailsShow = true
        this.clickedNode = e.item._cfg.id
        this.handleCenterShow(e.item._cfg.id)
      })
      this.graph.on('node:dragstart', e => {
        this.graph.layout()
        this.refreshDragedNodePosition(e)
      })

      this.graph.on('node:drag', e => {
        this.graph.get('layoutController').layoutMethod.execute()
        this.refreshDragedNodePosition(e)
      })

      this.graph.on('node:dragend', e => {
        e.item.get('model').fx = null
        e.item.get('model').fy = null
      })

      this.graph.on('node:touchstart', e => {
        this.clickedNode = e.item._cfg.id
        this.handleCenterShow(e.item._cfg.id)
      })
    },
    handleNodeDsc(nodeData) {
      nodeData.forEach(v => {
        this.nodeDsc[v.id] = v
      })
    },
    async handleSpreadNodes(id) {
      this.isLoading = true
      const {
        data: { data: getData },
      } = await axios.get(
        `/find/default/multi/${this.nodesMap[id].nodeLabel}?id=${id}`,
        {
          headers: {
            Authorization: `${getToken()}`,
          },
        },
      )
      this.isLoading = false
      // console.log(getData)
      this.spreadData = getData
      this.spreadData.nodes.forEach(v => {
        if (v['中文名']) v.label = v['中文名']
        else v.label = v.name
        v.label = this.fittingString(v.label, 85, 18)
      })
      this.handleNodeDsc(this.spreadData.nodes)
      this.handleCenterShow(this.spreadData.center)
      this.spreadList[this.spreadData.center] = true
      let edgeList = []
      Object.keys(this.spreadData.edges).forEach(v => {
        edgeList = [...edgeList, ...this.spreadData.edges[v]]
      })
      this.spreadData.edges = edgeList
      this.spreadData.nodes.forEach(v => {
        this.nodesMap[v.id] = v
        if (!this.idMap.has(v.id)) {
          this.idMap.add(v.id)
        } else {
          return
        }
        this.graphData.nodes.push(v)
      })
      this.spreadData.edges.forEach(v => {
        if (!this.idMap.has(v.id)) {
          this.idMap.add(v.id)
        } else {
          return
        }
        this.graphData.edges.push(v)
      })
      this.graphData.nodes.forEach(v => {
        if (v.id === this.spreadData.center) {
          v.labelCfg = {
            style: {
              fill: '#fff',
              fontSize: 18,
            },
          }
          v.style = {
            fill: '#14213D',
            stroke: '#14213D',
            cursor: 'pointer',
          }
        }
      })
      const labelList = new Map()
      let lableIndex = 0
      this.graphData.nodes.forEach(v => {
        if (!labelList.has(v.nodeLabel)) {
          labelList.set(v.nodeLabel, lableIndex++)
        }
      })

      this.graphData.nodes.forEach(v => {
        v.style = {
          fill: this.colorList[labelList.get(v.nodeLabel) % 7],
          stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
          cursor: 'pointer',
        }
        v.stateStyles = {
          active: {
            fill: this.colorList[labelList.get(v.nodeLabel) % 7],
            stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
            'text-shape': {
              fill: '#000',
            },
          },
          inactive: {
            fill: this.colorList[labelList.get(v.nodeLabel) % 7],
            stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
          },
        }
      })

      this.multiEdgeMap = {}
      this.handleGraphMap(this.graphData)
      this.handleMultiEdges()
      this.graph.data(this.graphData)
      this.graphEventInit()
      this.graph.updateLayout({
        nodeStrength: -25,
        linkDistance: 230,
        preventOverlap: true,
        nodeSpacing: 32,
        nodeSize: 100,
      })
      this.graph.render()
    },
    handleCenterShow(center) {
      this.centerTitle =
        this.nodeDsc[center]['中文名'] || this.nodeDsc[center].name
      this.centerList = {}
      // console.log(center)
      this.summary.isable = this.nodesMap[center].summary ? true : false
      if (this.nodesMap[center].summary) {
        this.summary.content = this.nodesMap[center].summary
          .replaceAll('<a>', '')
          .replaceAll('<，a>', '')
        // console.log(this.summary.content)
      }
      Object.keys(this.nodeDsc[center])
        // .slice(0, 7)
        .forEach(v => {
          this.centerList[v] = this.nodeDsc[center][v]
        })
    },
    handleGraphMap(graphData) {
      graphData.edges.forEach((v, index) => {
        if (!v.label) v.label = v.type
        if (v.type) {
          delete v.type
        }
        if (!this.multiEdgeMap[v.target]) {
          this.multiEdgeMap[v.target] = {}
        }
        this.multiEdgeMap[v.target][v.source]
          ? this.multiEdgeMap[v.target][v.source].push(v)
          : (this.multiEdgeMap[v.target][v.source] = [v])
        this.multiEdgeMap[v.target][v.source][
          this.multiEdgeMap[v.target][v.source].length - 1
        ].index = index
      })
    },
    handleMultiEdges() {
      Object.keys(this.multiEdgeMap).forEach(v => {
        Object.keys(this.multiEdgeMap[v]).forEach(w => {
          const data = this.multiEdgeMap[v][w]
          if (data.length > 1) {
            // console.log(data)
            const tempOffset = Math.floor(100 / (data.length - 1))
            let nowOffset = -50
            data.forEach(i => {
              this.graphData.edges[i.index] = {
                ...this.graphData.edges[i.index],
                type: 'quadratic',
                curveOffset: nowOffset,
              }
              nowOffset += tempOffset
            })
          }
        })
      })
    },
    handleVideoGraph() {
      let edgeList = []
      Object.keys(this.graphData.edges).forEach(v => {
        edgeList = [...edgeList, ...this.graphData.edges[v]]
      })
      this.graphData.edges = edgeList
      this.handleGraphMap(this.graphData)

      const labelList = new Map()
      let lableIndex = 0

      this.graphData.nodes.forEach(v => {
        this.nodesMap[v.id] = v
        if (!this.idMap.has(v.id)) {
          this.idMap.add(v.id)
        }

        if (!labelList.has(v.nodeLabel)) {
          labelList.set(v.nodeLabel, lableIndex++)
        }
      })
      this.graphData.nodes.forEach(v => {
        v.style = {
          fill: this.colorList[labelList.get(v.nodeLabel) % 7],
          stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
          cursor: 'pointer',
        }
        v.stateStyles = {
          active: {
            fill: this.colorList[labelList.get(v.nodeLabel) % 7],
            stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
            'text-shape': {
              fill: '#000',
            },
          },
          inactive: {
            fill: this.colorList[labelList.get(v.nodeLabel) % 7],
            stroke: this.colorList[labelList.get(v.nodeLabel) % 7],
          },
        }
      })

      this.graphData.edges.forEach(v => {
        if (!this.idMap.has(v.id)) {
          this.idMap.add(v.id)
        }
      })
      this.handleMultiEdges()
      this.handleNodeDsc(this.graphData.nodes)
      this.handleCenterShow(this.graphData.center)
      this.graph.data(this.graphData)
      this.graphEventInit()
      this.graph.updateLayout({
        nodeStrength: -25,
        linkDistance: 230,
        preventOverlap: true,
        nodeSpacing: 32,
        nodeSize: 100,
        // forceSimulation: this.forceSimulation()
      })
      this.graph.render()
    },
    fittingString(str, maxWidth, fontSize) {
      const ellipsis = '...'
      const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0]
      let currentWidth = 0
      let res = str
      const pattern = new RegExp('[\u4E00-\u9FA5]+')
      if (str)
        str.split('').forEach((letter, i) => {
          if (currentWidth > maxWidth - ellipsisLength) return
          if (pattern.test(letter)) {
            currentWidth += fontSize
          } else {
            currentWidth += G6.Util.getLetterWidth(letter, fontSize)
          }
          if (currentWidth > maxWidth - ellipsisLength) {
            res = `${str.substr(0, i)}${ellipsis}`
          }
        })
      return res
    },
    handleGraphUpdate(newV) {
      if (!this.graph) {
        this.graphInit()
      }
      this.graphRefresh()
      this.graph.center = newV.center
      this.spreadList[newV.center] = true
      this.clickedNode = newV.center
      this.graphData = newV
      // console.log(newV.center)
      this.graphData.nodes.forEach(v => {
        if (v['中文名']) v.label = v['中文名']
        else v.label = v.name
        v.label = this.fittingString(v.label, 50, 16)
        if (v.id === newV.center) {
          v.labelCfg = {
            style: {
              fill: '#fff',
              fontSize: 18,
            },
          }
          v.style = {
            fill: '#14213D',
            stroke: '#14213D',
            cursor: 'pointer',
          }
        }
        // if (v.nodeLabel === this.selectedType) {
        //   v.style = {
        //     fill: '#14213D',
        //     stroke: '#14213D',
        //     cursor: 'pointer'
        //   }
        // }
      })
      // switch (this.graphData.graphType) {
      //   case 'Video':
      //     this.handleVideoGraph()
      //     break
      //   case 'Person':
      //     this.handleVideoGraph()
      //     break
      //   default:
      //     break
      // }
      this.handleVideoGraph()
    },
    emitSpread() {
      this.handleSpreadNodes(this.clickedNode)
    },
  },
  watch: {
    parentgraphData(newV) {
      this.isSummaryShow = true
      if (newV) {
        this.$nextTick(() => {
          this.handleGraphUpdate(newV)
        })
      }
    },
  },
}
</script>

<style lang="scss">
.summary-wrap {
  max-height: 500px;
  overflow: auto;
}
#graph {
  position: relative;
}

.g6-component-toolbar {
  z-index: 10;
}
</style>
